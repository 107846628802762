import { render, staticRenderFns } from "./PreviewFriendGetFriend.vue?vue&type=template&id=2aaf0911&scoped=true"
import script from "./PreviewFriendGetFriend.vue?vue&type=script&lang=js"
export * from "./PreviewFriendGetFriend.vue?vue&type=script&lang=js"
import style0 from "./PreviewFriendGetFriend.vue?vue&type=style&index=0&id=2aaf0911&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2aaf0911",
  null
  
)

export default component.exports